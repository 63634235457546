import logo from "./logo.svg";
import "./App.css";
import {
  Navbar,
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
} from "react-bootstrap";
import ReactEcharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import { createSignalRContext } from "react-signalr/signalr";
import DatePicker from "react-datepicker";
import { BrowserRouter, Link } from "react-router-dom";
import { format } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

const SignalRContext = createSignalRContext();

function App() {
  const [lastUpdate, setLastUpdate] = useState("");
  const [aqiValue, setAQIValue] = useState(0);
  const [pm25Value, setPM25Value] = useState(0);
  const [pm10Value, setPM10Value] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [report, setReport] = useState(null);
  const [chartData, setChartData] = useState(null);

  const MainURL = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
  //const MainURL = `http://localhost:13000`;

  const AQIoption = {
    series: [
      {
        type: "gauge",
        min: 0,
        max: 300,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 20,
            color: [
              [0.04, "#3BCCFF"],
              [0.166, "#92D050"],
              [0.33, "#FDDD60"],
              [0.66, "#FFA200"],
              [1, "#F04646"],
            ],
          },
        },
        axisLabel: {
          color: "inherit",
          distance: 0,
          fontSize: 0,
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-65%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        title: {
          offsetCenter: [0, "30%"],
          fontSize: 20,
        },
        detail: {
          fontSize: 30,
          offsetCenter: [0, "-20%"],
          valueAnimation: true,
          formatter: function (value) {
            return Math.round(value * 100) / 100.0;
          },
          color: "inherit",
        },
        data: [
          {
            value: aqiValue,
            name: "AQI",
          },
        ],
      },
    ],
  };
  const PM25option = {
    series: [
      {
        type: "gauge",
        min: 0,
        max: 100,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 20,
            color: [
              [0.15, "#3BCCFF"],
              [0.25, "#92D050"],
              [0.375, "#FDDD60"],
              [0.75, "#FFA200"],
              [1, "#F04646"],
            ],
          },
        },
        axisLabel: {
          color: "inherit",
          distance: 0,
          fontSize: 0,
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-65%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        title: {
          offsetCenter: [0, "30%"],
          fontSize: 20,
        },
        detail: {
          fontSize: 30,
          offsetCenter: [0, "-20%"],
          valueAnimation: true,
          formatter: function (value) {
            return Math.round(value * 100) / 100.0;
          },
          color: "inherit",
        },
        data: [
          {
            value: pm25Value,
            name: "PM 2.5\n(Avg. 24 Hr.)\n(µg./m3)",
          },
        ],
      },
    ],
  };
  const PM10option = {
    series: [
      {
        type: "gauge",
        min: 0,
        max: 250,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 20,
            color: [
              [0.2, "#3BCCFF"],
              [0.32, "#92D050"],
              [0.48, "#FDDD60"],
              [0.72, "#FFA200"],
              [1, "#F04646"],
            ],
          },
        },
        axisLabel: {
          color: "inherit",
          distance: 0,
          fontSize: 0,
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-65%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        title: {
          offsetCenter: [0, "30%"],
          fontSize: 20,
        },
        detail: {
          fontSize: 30,
          offsetCenter: [0, "-20%"],
          valueAnimation: true,
          formatter: function (value) {
            return Math.round(value * 100) / 100.0;
          },
          color: "inherit",
        },
        data: [
          {
            value: pm10Value,
            name: "PM 10\n(µg./m3)",
          },
        ],
      },
    ],
  };

  SignalRContext.useSignalREffect(
    "update", // Your Event Key
    (message) => {
      setLastUpdate(
        new Date(message.ts).toLocaleString("en-US", { timeZoneName: "short" })
      );
      setAQIValue(message.aqi);
      setPM25Value(message.P25);
      setPM10Value(message.P10);
    }
  );

  const getReport = () => {
    fetch(`${MainURL}/api/historical/range`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: startDate,
        to: endDate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setChartData(null);
        setReport(data);
        //console.log(data);
      })
      .catch((error) => {
        setReport(null);
        console.error("Error:", error);
      });
  };

  const getChart = () => {
    fetch(`${MainURL}/api/historical/range`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        from: startDate,
        to: endDate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setReport(null);
        let chartDataRaw = {};
        chartDataRaw["aqi"] = [];
        chartDataRaw["pM2p5"] = [];
        chartDataRaw["pM10"] = [];
        chartDataRaw["timestamp"] = [];
        data.forEach((item) => {
          chartDataRaw["aqi"].push(item.aqi.toFixed(2));
          chartDataRaw["pM2p5"].push(item.pM2p5.toFixed(2));
          chartDataRaw["pM10"].push(item.pM10.toFixed(2));
          chartDataRaw["timestamp"].push(format(new Date(item.timestamp),"dd MMM yyyy HH:mm"));
        });
        let chartOption = {
          color: ["blue", "red"],
          title: {
            text: "AQI Historical Data",
          },
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["AQI", "PM 2.5"],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: chartDataRaw.timestamp,
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "AQI",
              type: "line",
              data: chartDataRaw.aqi,
              symbol: "emptyCircle",
              symbolSize: 10,
              lineStyle:{
                color: "blue",
                type: "solid",
                dashOffset: 0.5
              }
            },
            {
              name: "PM 2.5",
              type: "line",
              data: chartDataRaw.pM2p5,
              symbol: "diamond",
              symbolSize: 10,
              lineStyle:{
                color: "red",
                type: "dashed",
                dashOffset: 0.5
              }
            },
            // {
            //   name: 'PM 10',
            //   type: 'line',
            //   data: chartDataRaw.pM10
            // }
          ],
        };
        setChartData(chartOption);
      })
      .catch((error) => {
        setChartData(null);
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    let ds = new Date();
    ds.setHours(0, 0, 0, 0);
    ds.setDate(ds.getDate() - 1);
    setStartDate(ds);

    let de = new Date();
    de.setHours(0, 0, 0, 0);
    setEndDate(de);
  }, []);
  return (
    <SignalRContext.Provider
      connectEnabled={true}
      url={`${MainURL}/realtime`}
    >
      <BrowserRouter>
        <div className="App">
          <Navbar bg="dark" variant="dark" className="navbar-expand-lg">
            <Container>
              <Navbar.Brand href="">
                <img
                alt=""
                src={logo}
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{" "}
                AIR4UBRU
              </Navbar.Brand>
            </Container>
          </Navbar>
          <Container className="my-5">
            <Row>
              <Col>
                <Row>
                  <h4 className="text-start">Air Quality</h4>
                </Row>
                <Row>
                  <span className="text-start">Last update : {lastUpdate}</span>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={6} md={6} className="item-padding">
                <Card className="border-left-primary shadow h-100">
                  <Card.Body>
                    <Row className="no-gutters align-items-center">
                      <div className="mb-0">
                        <ReactEcharts option={AQIoption} />
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6} md={6} className="item-padding">
                <Card className="border-left-primary shadow h-100">
                  <Card.Body>
                    <Row className="no-gutters align-items-center">
                      <div className="mb-0">
                        <ReactEcharts option={PM25option} />
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col xl={4} md={6} className="item-padding">
              <Card className="border-left-primary shadow h-100">
                <Card.Body>
                  <Row className="no-gutters align-items-center">
                    <div className="mb-0">
                      <ReactEcharts option={PM10option} />
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
            </Row>
            <Row style={{ paddingTop: "2rem" }}>
            <Table responsive>
            <tbody>
              <tr>
                <td style={{"width": Math.round(window.innerWidth * (25/100))}}>ความหมายของช่วงสี</td>
                <td style={{"width": Math.round(window.innerWidth * (15/100)), "backgroundColor": "#3BCCFF"}}>ดีมาก</td>
                <td style={{"width": Math.round(window.innerWidth * (15/100)), "backgroundColor": "#92D050"}}>ดี</td>
                <td style={{"width": Math.round(window.innerWidth * (15/100)), "backgroundColor": "#FFFF00"}}>ปานกลาง</td>
                <td style={{"width": Math.round(window.innerWidth * (15/100)), "backgroundColor": "#FFA200"}}>เริ่มมีผลกระทบต่อสุขภาพ</td>
                <td style={{"width": Math.round(window.innerWidth * (15/100)), "backgroundColor": "#F04646"}}>มีผลกระทบต่อสุขภาพ</td>
              </tr>
            </tbody>
            </Table>
            </Row>
            

            <Row style={{ paddingTop: "2rem" }}>
              <Col>
                <Row>
                  <h4 className="text-start">Historical Data</h4>
                </Row>
                <Row>
                  <Col className="text-start" style={{ paddingTop: "1rem" }}>
                    <span className="text-start">
                      <span>Start Date : </span>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy HH:mm"
                        timeIntervals={60}
                        timeFormat="HH:mm"
                      />
                    </span>
                  </Col>
                  <Col className="text-start" style={{ paddingTop: "1rem" }}>
                    <span className="text-start">
                      <span>End Date : </span>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        showTimeSelect
                        dateFormat="dd MMM yyyy HH:mm"
                        timeIntervals={60}
                        timeFormat="HH:mm"
                      />
                    </span>
                  </Col>
                  <Col className="text-start" style={{ paddingTop: "1rem" }}>
                    <Button variant="primary" onClick={getReport}>
                      Get Table Report
                    </Button>
                  </Col>
                  <Col className="text-start" style={{ paddingTop: "1rem" }}>
                    <Button variant="primary" onClick={getChart}>
                      Get Chart Report
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ paddingTop: "2rem" }}>
              <Col>
                {report ? (
                  <>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Date Time</th>
                          <th>AQI</th>
                          <th>PM 2.5</th>
                          {/* <th>PM 10</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {report.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {format(new Date(item.timestamp),"dd MMM yyyy HH:mm")}
                              </td>
                              <td>{item.aqi.toFixed(2)}</td>
                              <td>{item.pM2p5.toFixed(2)}</td>
                              {/* <td>{item.pM10.toFixed(2)}</td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <span></span>
                )}
                {chartData ? (
                  <>
                    <ReactEcharts option={chartData} />
                  </>
                ) : (
                  <span></span>
                )}
              </Col>
            </Row>
            <Row style={{ paddingTop: "2rem" }}>
              <Col xl={12} md={12} className="item-padding">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>ค่าดัชนีคุณภาพอากาศ (AQI)</th>
                      <th>สีที่ใช้</th>
                      <th>ความหมาย (ระดับคุณภาพอากาศ)</th>
                      <th>ข้อควรปฏิบัติ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0 - 25</td>
                      <td className="td_center">
                        <span className="circle_lv1"></span>
                      </td>
                      <td>ดีมาก</td>
                      <td className="td_left">
                        ประชาชนทุกคนสามารถดำเนินชีวิตได้ตามปกติ
                      </td>
                    </tr>
                    <tr>
                      <td>26 - 50</td>
                      <td className="td_center">
                        <span className="circle_lv2"></span>
                      </td>
                      <td>ดี</td>
                      <td className="td_left">
                        <b>ประชาชนทั่วไป :</b> สามารถทำกิจกรรมกลางแจ้งได้ตามปกติ
                        <br />
                        <b>ประชาชนกลุ่มเสี่ยง</b> : ควรสังเกตอาการผิดปกติ เช่น
                        ไอบ่อย หายใจลำบาก หายใจถี่ หายใจไม่ออก หายใจมีเสียงวี้ด
                        แน่นหน้าอก เจ็บหน้าอก ใจสั่น คลื่นไส้ เมื่อยล้าผิดปกติ
                        หรือ วิงเวียนศีรษะ
                      </td>
                    </tr>
                    <tr>
                      <td>51 - 100</td>
                      <td className="td_center">
                        <span className="circle_lv3"></span>
                      </td>
                      <td>ปานกลาง</td>
                      <td className="td_left">
                        <b>ประชาชนทั่วไป :</b>{" "}
                        ลดระยะเวลาการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก
                        <b>ประชาชนกลุ่มเสี่ยง :</b>
                        <ul>
                          <li>
                            ใช้อุปกรณ์ป้องกันตนเอง เช่น หน้ากากป้องกัน PM2.5
                            ทุกครั้ง ที่ออกนอกอาคาร
                          </li>
                          <li>
                            ลดระยะเวลาการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก
                          </li>
                          <li>หากมีอาการผิดปกติให้รีบปรึกษาแพทย์</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>101 - 200</td>
                      <td className="td_center">
                        <span className="circle_lv4"></span>
                      </td>
                      <td>เริ่มมีผลกระทบต่อสุขภาพ</td>
                      <td className="td_left">
                        <b>ประชาชนทั่วไป :</b>
                        <ul>
                          <li>
                            ใช้อุปกรณ์ป้องกันตนเอง เช่น หน้ากากป้องกัน PM2.5
                            ทุกครั้งที่ออกนอกอาคาร
                          </li>
                          <li>
                            จำกัดระยะเวลาในการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก
                          </li>
                          <li>
                            ควรสังเกตอาการผิดปกติ เช่น ไอ หายใจลำบาก
                            ระคายเคืองตา
                          </li>
                        </ul>
                        <b>ประชาชนกลุ่มเสี่ยง :</b>
                        <ul>
                          <li>
                            ใช้อุปกรณ์ป้องกันตนเอง เช่น หน้ากากป้องกัน PM2.5
                            ทุกครั้งที่ออกนอกอาคาร
                          </li>
                          <li>
                            เลี่ยงการทำกิจกรรมหรือการออกกำลังกายกลางแจ้งที่ใช้แรงมาก
                          </li>
                          <li>
                            ให้ปฏิบัติตามคำแนะนำของแพทย์
                            หากมีอาการผิดปกติให้รีบไปพบแพทย์
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>201 ขึ้นไป</td>
                      <td className="td_center">
                        <span className="circle_lv5"></span>
                      </td>
                      <td>มีผลกระทบต่อสุขภาพ</td>
                      <td className="td_left">
                        <b>ประชาชนทุกคน</b>
                        <ul>
                          <li>งดกิจกรรมกลางแจ้ง</li>
                          <li>
                            หากมีความจำเป็นต้องทำกิจกรรมกลางแจ้งให้ใช้อุปกรณ์ป้องกันตนเองทุกครั้ง
                            เช่น หน้ากากป้องกัน PM2.5
                          </li>
                          <li>หากมีอาการผิดปกติให้รีบไปพบแพทย์</li>
                          <li>
                            ผู้ที่มีโรคประจำตัว
                            ควรอยู่ในพื้นที่ปลอดภัยจากมลพิษทางอากาศ
                            ให้เตรียมยาและอุปกรณ์ที่จำเป็นให้พร้อมและปฏิบัติตามคำแนะนำของแพทย์อย่างเคร่งครัด
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <p><small>
                อ้างอิงเกณฑ์และการคำนวณจาก{" "}
                  <Link to="http://air4thai.pcd.go.th/webV3/#/AQIInfo" target="_blank">Air4Thai</Link>{" "}
                  กรมควบคุมมลพิษ
                </small>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </BrowserRouter>
    </SignalRContext.Provider>
  );
}

export default App;
